<template>
 <div>

    <v-row no-gutters class="pa-2 mt-8">
          <v-col cols="6" class="pa-1" v-for="(item, index) in credits" :key="index">
            <v-card  dark class="rescard">
              <v-btn color="tertiary"  dark x-large block  @click="credit_amount=item, confirm=true"> 
                <img :src="$store.state.img_dir+'coins.png'" height="24px"  class="ma-auto"/> <span class="_headtext-md text-gold">{{$money(item)}}</span>
              </v-btn>
            </v-card>
            </v-col>
            <v-col cols="12">
        <v-alert
        border="top"
        colored-border
        type="warning"
        class="orange--text ma-2"
        elevation="2"
        >
        Important: Convenience Fee will be charge by using this service.
        </v-alert>
            </v-col>
    </v-row>
    <!-- <v-row no-gutters class="pa-2">
      <v-btn color="primary"  dark large block  @click="confirm=true">BUY</v-btn>
    </v-row> -->
    <va-buy-process :amount="credit_amount" :show="confirm" :user="user" @DialogEvent="hEvent"/>
</div>
</template>
<script>
import {
  mapMutations
} from 'vuex'
  export default {
    data: () => ({
      credits:[ 100, 200, 300, 400, 500, 1000, 1500,2000, 3000, 5000, 10000, 20000],
      confirm: false,
      credit_amount: 0
    }),
    created() {
      this.PING()
     this.$http.defaults.headers.common['Authorization'] = 'Bearer ' + this.user.token
      // this.userDetails()
    },
     mounted(){
       this.setLoggedIn(true)
       this.setDrawer(false)
        if (this.user == null) {
          this.$router.push('/')
        }else {
          this.$http.defaults.headers.common['Authorization'] = 'Bearer ' + this.user.token
         console.log(this.user)
        }
    },
    computed:{
      isOnline(){
        return this.$store.state.is_online
      },
      user() {
        if(localStorage.user){
            return JSON.parse(localStorage.user)
        }
        return null
      },
     
    },
    methods: {
      ...mapMutations(['setDrawer','setAlert', 'setAlertColor', 'setAlertText', 'setLoggedIn', 'setOverlayLoading']),
      hEvent() {
        this.confirm = false
      }
      
    },
  }
</script>